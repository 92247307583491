import { Layout } from '@/components/layout';

import {
    JsonldOrganization,
    JsonldProductItemList,
    JsonldQuestionList,
    TOrganizationData,
    TProductData,
    TQuestionData,
} from '@dengigroup/seo-jsonld-markup';
import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import type { NextPage, NextPageContext } from 'next';
import Head from 'next/head';
import React from 'react';

import { getFaqs } from '@/api/arenta/faq-service';
import { getMetaDataPage } from '@/api/arenta/metadata-service';
import { getSliders } from '@/api/arenta/sliders-service';
import { StyledContainer } from '@/components/_shared/container';
import { LinkPageSection } from '@/components/elements/page-section';
import { PageSection } from '@/components/elements/page-section/ui/page-section';
import { CatchingErrorLayout } from '@/components/layout/catching-error';
import { Announcement } from '@/components/modules/announcement';
import { getArticles } from '@/components/modules/articles-page/utils/dynamic-page-props';
import { Benefits } from '@/components/modules/benefits';
import { Categories } from '@/components/modules/categories';
import { Faq } from '@/components/modules/faq';
import { HowToUse } from '@/components/modules/how-to-use';
import { PopularArticles } from '@/components/modules/popular-articles';
import { PopularProducts } from '@/components/modules/popular-products';
import { Sliders } from '@/components/modules/sliders';
import { ARTICLES_URL, QUESTIONS_URL } from '@/constants/paths';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/constants/settings';
import { wrapperReduxToolkit } from '@/store';
import { loadWithPopularProducts } from '@/store/utils/load-with-popular-products';
import { Article } from '@/types/articles-page';
import { TFaq } from '@/types/faq';
import { TRentProduct } from '@/types/products';
import { TSlider } from '@/types/sliders';

import { useTranslate } from '@/shared/hooks/use-translate';

export type TIndexPageProps = {
    title: string;
    description: string;
    articles: Article[];
    sliders: TSlider[];
    faqs: TFaq[];
    statusCode: number;
    popularProducts: TRentProduct[];
};

const Home: NextPage<TIndexPageProps> = ({
    articles,
    faqs,
    statusCode,
    sliders,
    description,
    title,
    popularProducts,
}) => {
    const { t } = useTranslate();

    const organization: TOrganizationData = {
        nameCompany: 'ТОО "RENTAL SYSTEM"',
        phone: '+7 (700) 077-55-77',
        addressCountry: 'Казахстан',
        addressLocality: 'город Костанай',
        addressRegion: 'Костанайская область',
        streetAddress: 'улица В.Чкалова, дом 9, н.п. 62',
        postalCode: '110000',
    };

    const seoLinkedProducts: TProductData[] = popularProducts?.map((product) => {
        return {
            price: product.prices?.priceForThirtyDays?.toString() ?? '',
            image: product.photos?.[0]?.image ?? '',
            name: product.name,
            priceCurrency: 'KZT',
            inStock: product.status === 'active',
        };
    });

    const seoLinkedQuestions: TQuestionData[] = faqs.map((faq) => {
        return {
            question: faq.question,
            answer: faq.answer,
        };
    });
    return (
        <CatchingErrorLayout statusCode={statusCode}>
            <Layout>
                <Head>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Head>
                <JsonldOrganization data={organization} />
                {popularProducts?.length > 0 && <JsonldProductItemList data={seoLinkedProducts} url="/" />}
                {faqs.length > 0 && <JsonldQuestionList data={seoLinkedQuestions} url="/" />}
                <StyledContainer>
                    <Sliders sliders={sliders} />
                    <HowToUse />
                    <Categories />
                    <Announcement />
                    {popularProducts?.length > 0 && (
                        <PageSection title={t('Самые популярные 🔥')}>
                            <PopularProducts idSlider="popular" products={popularProducts} />
                        </PageSection>
                    )}
                    <Benefits />
                    <PageSection
                        title={t('Самые частые вопросы')}
                        linkComponent={<LinkPageSection title={t('Все вопросы')} href={QUESTIONS_URL} />}
                    >
                        <Faq faqList={faqs} />
                    </PageSection>
                    <PageSection
                        title={t('Полезные материалы')}
                        linkComponent={<LinkPageSection title={t('Смотреть все')} href={ARTICLES_URL} />}
                    >
                        <PopularArticles articles={articles} />
                    </PageSection>
                </StyledContainer>
            </Layout>
        </CatchingErrorLayout>
    );
};

export default Home;

Home.getInitialProps = wrapperReduxToolkit.getInitialPageProps(
    ({ dispatch, getState }) =>
        async (context: NextPageContext) => {
            const { res, locale } = context;

            const { popularProducts } = await loadWithPopularProducts({
                getState,
                dispatch,
                locale: locale as string,
                context,
            });

            let statusCode = STATUS_CODE_OK;

            try {
                const response = await Promise.all([
                    getArticles(1, locale, undefined, true),
                    getFaqs(locale as string, true),
                    getSliders(locale),
                    getMetaDataPage('/', locale as string),
                ]);

                return {
                    articles: response[0].data,
                    faqs: response[1].data,
                    sliders: response[2].data,
                    popularProducts,
                    ...response[3],
                };
            } catch (err) {
                const error = err as AxiosError;

                statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;

                /* eslint-disable no-console */
                console.error('Home.getInitialProps error', error);

                Sentry.captureException(error);
            }

            if (res) {
                res.statusCode = statusCode;
            }

            return { statusCode };
        },
);
