import { styled } from 'styled-components';

import { mq } from '@/components/_shared/media.styled';
import { Button as ButtonUi } from '@/components/elements/UI/button';

export const Wrapper = styled.div`
    margin: 42px 0;
    position: relative;
    display: flex;
    padding: 32px;
    width: 100%;
    ${mq.lt.sm} {
        flex-direction: column;
        align-items: center;
    }
`;

export const ButtonBox = styled.div`
    margin: 24px 0 0;
    position: relative;
    width: 100%;
    display: flex;
    gap: 10px;
    ${mq.lt.sm} {
        margin: 24px 0;
        width: 100%;
        flex-direction: column;
    }
`;

export const Button = styled(ButtonUi)`
    ${mq.lt.sm} {
        width: 100%;
    }
`;

export const Section = styled.div<{ $alignItems: 'flexStart' | 'center'; width?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({ $alignItems }) => ($alignItems === 'flexStart' ? 'flex-start' : 'center')};
    ${mq.gt.xs} {
        ${({ width }) => width && `width: ${width}`};
    }
`;

export const Title = styled.h3`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 15px;
`;

export const Line = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 15px;
`;
